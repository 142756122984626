<template>
	<div class="section_right">
		<div class="sub_top">
            <div class="sub_title"><i class="far fa-history text-primary"></i>{{ $t('coin.100') }}</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->
			<div class="notice_table04">
				<table style="width:100%" class="main_table02">
					<thead>
						<tr>
							<th>{{ $t('assets.2') }}</th>
                            <th>{{ $t('assets.4') }}</th>
                            <th>{{ $t('assets.15') }}</th>
                            <th>{{ $t('assets.14') }}</th>
                            <th>{{ $t('assets.3') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in list" :key="index">
							
							<td>{{item.date}}</td>
							<td>{{numberWithCommas(item.point)}}</td>
							<td>{{CheckType(item.type)}}</td>
							<td>{{CheckTarget(item.target)}}</td>
                            <td>{{item.t_info}}</td>
						</tr>
					</tbody>
					<tbody></tbody>
				</table>
			</div>
		</div>
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");

export default {
	data(){
		return{
			list : [],
		}
	},
	mounted(){
		this.GetPointHistoryList()
	},
	methods : {
		CheckType(type){
				
            if(type=='P'){
                return this.$t('assets.20')
            }else if(type=='M'){
                return this.$t('withdraw_point.37')
            }
        },
        CheckTarget(target){
            if(target =='J'){
                return this.$t('withdraw_point.38')
            }else if(target =='JR'){
                return this.$t('withdraw_point.39')
            }else if(target =='T'){
                return this.$t('transfer.1')
            }

        },     
		GetPointHistoryList(){
							
			const body = { };
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/point/GetPointHistoryList`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							this.list = body.list	
						}else if(res.data.code=='9999'){
							this.$store.dispatch('SETLOGOUT').then(
								()=>{
									window.location.href= `/${this.$i18n.locale}/login`;
								}
							)
						}
					}

				}
			).catch(() => {});
			
			
		},
        numberWithCommas(x) {
			var parts=x.toString().split(".");
			return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
		},
	}
}
</script>
<style>
</style>